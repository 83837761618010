import React, { lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { BeforeInstallPromptEvent } from "./interface";
const Main = lazy(() => import("./components/main/Main"));
const Application = lazy(() => import("./components/application/Application"));

function App() {
  const [pwaData, setPwaData] = useState<BeforeInstallPromptEvent | null>(null);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      window.addEventListener("beforeinstallprompt", (event: Event) => {
        event.preventDefault();
        console.log("before installation prompt fired");
        setPwaData(event as BeforeInstallPromptEvent);
      });
    }

    return () => {
      isMounted = false;
    };
  }, []);

    //  Disable right-click using useEffect hook
    useEffect(() => {
      const handleContextMenu = (e: any) => {
        e.preventDefault();
      };
  
      document.addEventListener("contextmenu", handleContextMenu);
  
      return () => {
        document.removeEventListener("contextmenu", handleContextMenu);
      };
    }, []);

  const style: React.CSSProperties = {
    width: "100%",
    textAlign: "center",
    marginTop: "1em",
  };

  
  return (
    <>
      <Router>
        <Suspense fallback={<div style={style}>Loading.....</div>}>
          <Routes>
            <Route path="/" element={<Main pwa={pwaData} />} />
            <Route path="application" element={<Application />} />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
